import React from "react";

import './Refund.css';

import ContactCard from '../../../shared/components/ContactCard/ContactCard';


const Refund = props => {

    return (
        <>
            <section className="Refund__main-section-container">
                <div>
                    <h1 className="text-center Refund__main-title"> What is the refund process?</h1>
                </div>
                <div className="Refund__section-description">
                    <p>
                        At Cyber Sentinel LLC, <b>we want to make sure that you are 100% satisfied with the services you receive from us.</b> Please do not hesitate to contact us if you
                        have any technical or sales questions. If you feel that the services you have purchased are not suitable for you, and you have tried to solve the problem
                        with our support team, please let us know.
                    </p>
                    <br />
                    <p>

                        Follow the steps below for a full, no-hassle, <b>refund within 30 days of your date of purchase.</b> Please include your order number and why you’d like a
                        refund, so we can issue a refund as quickly as possible. We make every attempt to process the refund as quickly as possible. <b>
                        Our payment processor or your financial institution can take up to 1-5 days for the refund to reflect in your bank account/card.
                        </b>
                    </p>
                    <br />
                    <p className="text-decoration-underline">Requesting a Refund</p>

                    <ol className="Refund__ol-list">
                        <li style={{ wordBreak: 'break-word' }}>Contact Support by emailing help@yourcyber-sentinel.com or calling us at (866) 399-2780 .</li>
                        <li>If you choose to send an email, please clearly state in the subject line that you are requesting a refund and include your order identification number.</li>
                        <li>Include a detailed description of the reason for your refund in the email. If you are requesting a refund for only part of your order, please specify the
                            products for which you are requesting a refund. This information helps us improve our products.
                        </li>
                        <li>Once the request has been submitted, you will receive an automatic response notifying you that your request has been successfully submitted. The
                            support team will contact you as soon as possible.
                        </li>
                        <li>Once your request has been reviewed, Support will contact you to confirm the refund process and let you know how to proceed.</li>
                    </ol>

                </div>
            </section>

            <section className="Refund__customer-service-container">
                <div className="Refund__customer-service">
                    <ContactCard />
                </div>
            </section>

        </>
    );
};

export default Refund;  