import React from "react";
import './MembershipTerms.css'

import ContactCard from '../../../shared/components/ContactCard/ContactCard';

const MembershipTerms = props => {


    return (
        <>
            <section className="Membership__main-section-container">
                <div>
                    <h1 className="text-center Membership__main-title"> Terms of membership</h1>
                </div>
                <div className="Membership__section-description">
                    <p>
                        By accessing or using any part of the website, you agree that you have read, understand, and agree to be bound by this TOS. If you do not agree to be so
                        bound, do not access or use the website. You must read and accept this TOS before using any service or product provided by Cyber Sentinel LLC. This TOS
                        contains an arbitration provision and waiver of class-wide proceedings which may be enforced by the parties.
                    </p>
                    <p></p>
                    <p>
                        This terms of service (“TOS”) is a legally binding agreement made by and between Cyber Sentinel LLC (“we” or “us”) and you, personally and, if applicable, on
                        behalf of the entity for whom you are using this web site (collectively, you). This TOS governs your use of <strong>www.yourcybersentinel.com</strong> and all related web pages,
                        portals, and interfaces (collectively, the “website”) and the services we offer on the website (“services”), so please read it carefully.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>1. Your acceptance of the TOS</b>
                    </p>
                    <p>
                        This TOS is a contract between you and Cyber Sentinel LLC . You accept this contract when you (a) use the website; (b) use or attempt to use the services; (c)
                        sign, or submit your electronic signature to, this agreement; and/or (c) otherwise sign any contract for the services with us via other means. If you do not
                        accept this agreement, then you must not do any of these things.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                       <b> 2. Program Offer</b>
                    </p>
                    <p>

                    By joining our program you will be entitled to access to all features for protecting and securing identities available to program member. 
                    By placing your monthly recurring order of Cyber Sentinel you will be charged $44.99 Now and every 30 days thereafter 
                    until you cancel your subscription. You will receive an electronic notification 5 to 7 days prior to your transaction and 
                    receipt after each successful transaction

                    </p>
                    <p></p>
                    {/* <p className="text-decoration-underline">
                        3. Using the website
                    </p> */}
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>3. Offers void where prohibited</b>
                    </p>
                    <p></p>
                    <p>
                        All offers on our website or otherwise are void where prohibited by any applicable law or regulation.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>4. Eligibility</b>
                    </p>
                    <p></p>
                    <p>
                        Except as expressly provided below, the website may only be used by individuals and entities who can form legally binding contracts under applicable
                        law. No person under the age of 18 may use the website without the supervision of a parent or legal guardian. Your use of the website will be deemed to
                        be a representation that you are 18 years of age or older or using the website with the permission of your parent or guardian. We require that all
                        enrollments be made by individuals 18 years of age or older. No child may use the website on their own behalf or maintain their own website customer
                        account. The site is meant for use by individuals residing within the united states only; others may not use the site at this time.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b> 5. Permission for future contact</b>
                    </p>
                    <p></p>
                    <p>
                        Where required by law, we will seek your consent before sending you any marketing materials. To the extent allowed by law, the provision of your email
                        and phone number to us constitutes your prior express written consent, and electronic signature, authorizing us to contact you at that email address and
                        phone number, including through the possible use of an automatic telephone dialing system or artificial or prerecorded voice, live calls and text messages,
                        for both promotional and informational reasons. You agree that this consent may be assigned by us to third parties and affiliates.
                    </p>
                    <p></p>
                    <p>
                        You are not required to provide such consent in order to make a purchase as you can always contact us directly to arrange an alternate purchase method.
                        By providing your telephone number to us, you certify that this is your own number that you own, and not a line owned or used by another and that you
                        will immediately notify us if your number changes or is reassigned. You agree to indemnify us if this is not the case and if the future owner or user of the
                        number makes a claim against us for contact at that number. You may opt-out of such contact at any time and through any of the reasonable methods
                        outlined in our privacy policy.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>6. License and restrictions</b>
                    </p>
                    <p></p>
                    <p>
                        Subject to the terms and conditions of this TOS, you are hereby granted a limited, non-exclusive right to use the content and materials on our website in
                        the normal course of your use of the website. We will retain ownership of our intellectual property rights and you will not obtain any rights therein by
                        virtue of this TOS or otherwise, except as expressly set forth in this TOS. You will have no right to use, copy, display, perform, create derivative works
                        from, distribute, transmit or sub-license materials or content available on the website, except as expressly set forth in this TOS.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>7. Prohibited conduct</b>
                    </p>
                    <p></p>
                    <p>
                        In your use of the web site, you may not: (i) infringe any patent, trademark, trade secret, copyright, right of publicity or other right of any party; (ii) disrupt
                        or interfere with the security or use of the web site or any web sites linked to the web site; (iii) interfere with or damage the web site, including, without
                        limitation, through the use of viruses, cancel bots, Trojan horses, harmful code, flood pings, denial of service attacks, packet or ip spoofing, forged routing
                        or electronic mail address information, or similar methods or technology; (iv) impersonate another person or entity, misrepresent your affiliation with a
                        person or entity, including (without limitation) us, or use a false identity; (v) attempt to obtain unauthorized access to the web site; (vi) engage, directly or
                        indirectly, in transmission of spam, chain letters, junk mail or any other type of unsolicited solicitation; (vii) collect, manually or through an automatic
                        process, information about other users or the web site without their or our express written consent; (viii) submit false or misleading information to us; (ix)
                        violate any law, rule, or regulation; (x) engage in any activity that interferes with any third party’s ability to use or enjoy the web site; or (xii) assist or
                        encourage any third party in engaging in any activity prohibited by this TOS.
                    </p>
                    <p></p>
                    {/* <p className="text-decoration-underline">
                        8. Password and account security
                    </p> */}
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>8. Registration</b>
                    </p>
                    <p></p>
                    <p>
                        You may create your own account on the website by completing the online registration and membership processes on the website, and you must do so if
                        you would like to make a purchase. In doing so, you must provide us with accurate and complete registration information, and update it if this information
                        changes. It is particularly important to keep the email address associated with your account current because although you may be able to log into your
                        website account using an old email address, you will not be able to receive messages from us about your account or other matters.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>9. Accounts and passwords</b>
                    </p>
                    <p></p>
                    <p>
                        We will create an account for you and assign you, or allow you to select, a password. You must keep your password confidential. You will be responsible
                        for all use of your password, including, without limitation, any use by any unauthorized third party. You must notify us immediately if you believe your
                        password may be used by any unauthorized person or entity. For security purposes, we recommend you change your password often.
                    </p>
                    <p></p>
                    <p>
                        Under no circumstance should you respond to a request for your password? Our employees will never ask for your password in any manner via any
                        means of communication. You must notify us immediately if you receive such a request. We reserve the right to suspend or terminate your use of the
                        website if we believe that your password is being used without permission or otherwise in a manner that may disrupt the website.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>10. Privacy policy</b>
                    </p>
                    <p></p>
                    <p>
                        You agree to the terms of our privacy policy, which can be accessed <a href="https://www.yourcybersentinel.com/privacy-policy" target="_blank">here</a> and which is incorporated by reference into this TOS.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>11. Accuracy of information</b>
                    </p>
                    <p></p>
                    <p>
                        We attempt to ensure that the information on the website is complete and accurate; however, this information may contain typographical errors, pricing
                        errors, and other errors or inaccuracies. We assume no responsibility for such errors and omissions, and reserve the right to (i) revoke any offer stated on
                        the website; (ii) correct any errors, inaccuracies, or omissions; and (iii) make changes to prices, content, promotions, product descriptions or specifications,
                        or other information on the web site.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>12.  Cancellation provisions</b>
                    </p>
                    <p></p>
                    <p>
                        If you determine that you do not wish to use the services, then you are permitted to cancel by calling our customer care at <b>(866) 399-2780  </b> or by
                        notifying us in writing at customer service, Cyber Sentinel LLC <b>339 8th St N St. Petersburg, FL 33701.</b> You will remain liable, however, for any unpaid fees,
                        purchases, or charges incurred prior to your cancellation. If the company determines it is unable to bill the membership fee due hereunder to your
                        designated billing source, the company shall have the right to terminate this agreement in which event you will no longer have access to any of the
                        services.
                    </p>
                    <p></p>
                    <p>
                        In addition to the company’s termination rights set forth above, Company may elect in its sole discretion to keep this TOS in effect, but suspend your
                        access to all of the services, until such time (if any) as the company is able to bill the fee due hereunder to your designated billing source. No exception to
                        these cancellation provisions will be made except as required by law.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>13.  Hardware and software requirement</b>
                    </p>
                    <p></p>
                    <p>
                        As an online customer, you are agreeing to receive all notifications via email at the email address you provided to Cyber Sentinel LLC upon enrollment or
                        purchase of the Cyber Sentinel LLC products and services. To ensure receipt of all notifications, you must timely update any change to your email address on file
                        with Cyber Sentinel LLC.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>14.  Product descriptions</b>
                    </p>
                    <p></p>
                    <p>
                        Short product descriptions are found on the site. For more detailed descriptions, call Cyber Sentinel LLC directly.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>15. Family coverage</b>
                    </p>
                    <p></p>
                    <p>
                        If you elected family coverage, a qualifying family member shall mean the primary member’s spouse or domestic partner, the member’s dependents under
                        the age of twenty-five (25) who have the same permanent address as the member, any handicapped adult living in the same household with the member
                        who requires assistance from the family to manage their affairs, and parents (mother or father) of the member or their spouse or domestic partner who
                        have the same permanent address as the member, or who are registered in a senior assisted living facility, skilled nursing home, hospice, or who have
                        been deceased for twelve (12) months or less.
                    </p>
                    <p></p>
                    <p>
                        If you request enrollment of a child, you will be required to verify your status as the child’s parent or guardian by providing documents reasonably
                        requested by Cyber Sentinel LLC, including, without limitation, the child’s social security card and birth certificate, valid proof of your identification and proof of
                        your residence.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>16. Submitting a claim for reimbursement</b>
                    </p>
                    <p></p>
                    <p>
                        In the event that you incur covered expenses as part of an identity theft event, your recovery advocates will provide initial contact information to the
                        insurance carriers that provide the reimbursement plan, if applicable. You will be mailed an insurance company claim package. For a detailed description
                        of expenses eligible for reimbursement, see the description of the reimbursement plan.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>17. Product fees and payment</b>
                    </p>
                    <p></p>
                    <p>
                        If you are paying for these benefits directly to Cyber Sentinel LLC, then the following terms will apply:
                    </p>
                    <p className="text-decoration-underline">
                        <b>18. Charges</b>
                    </p>
                    <p></p>
                    <p>
                        based on your authorization, payments will be deducted from your credit or debit card monthly in the amount indicated. With sixty (60) days prior written
                        notice to you at the email address on file for the primary member, Cyber Sentinel LLC reserves the right to adjust the pricing. You are under no obligation to
                        continue the program (see cancellation & refund policy below).
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>19. Cancellation & refund policy</b>
                    </p>
                    <p></p>
                    <p>
                        you can cancel the service at any time. You can obtain a refund for the initial registration period if the service does not meet your expectations by calling &nbsp;
                        <b>(866) 399-2780 </b> or by notifying us in writing at customer service, Cyber Sentinel LLC <b>339 8th St N St. Petersburg, FL 33701</b> within the first 30 days of your
                        registration. After the initial 30 days, you may cancel your service at any time and can request a pro-rata refund for the then-current month. If you wish
                        to cancel your plan, please call the customer service number. If you are paying monthly, your plan will be cancelled at the end of the month in which the
                        cancellation request is received. You are responsible for making payment for the months during which benefits are in effect.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>20. Lapse for non-payment</b>
                    </p>
                    <p></p>
                    <p>
                        if payment is not received on the due date for the benefit period you elected, recovery Cyber Sentinel products and services will cease at the point at which
                        the plan is terminated for non-payment.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                    <b>21. Additional use restrictions</b>
                    </p>
                    <p></p>
                    <p>

                        You understand and agree that your access to and use of the website and Cyber Sentinel LLC products and services is subject to the following terms:
                    </p>
                    <p></p>
                    <p>
                        <b>22.</b> You agree to provide true, accurate, complete, and current information to Cyber Sentinel LLC about yourself and any minor children you are enrolling or
                        have enrolled in the website or any Cyber Sentinel LLC products and services. If Cyber Sentinel LLC determine or reasonably suspects that any information you
                        provided to Cyber Sentinel is false, incomplete, or inaccurate, Cyber Sentinel LLC may, in its sole discretion, suspend or terminate your access to and use of the
                        website and the Cyber Sentinel products and services and block all future access to and use of the website and Cyber Sentinel LLC products and services. You further
                        agree that Cyber Sentinel LLC will not be liable to you, your minor children, or any other person if Cyber Sentinel LLC suspend or terminate your access to and use of
                        the website and the Cyber Sentinel LLC products and services;
                    </p>
                    <p></p>
                    <p>
                        <b>23.</b> You agree not to copy, post, publish, broadcast, display, distribute or otherwise make available the website, in whole or in part, in any medium,
                        form or format, now known or later developed, without Cyber Sentinel LLC’s prior written authorization.
                    </p>
                    <p></p>
                    <p>
                        <b>24.</b> You agree that the website and Cyber Sentinel LLC products and services are provided solely for your personal use;
                    </p>
                    <p></p>
                    <p>
                        <b>25.</b> You agree not to alter, modify or reverse engineer or compile any part of the website, including, without limitation, Cyber Sentinel LLC products and
                        services, software programs, and applications provided through the website;
                    </p>
                    <p></p>
                    <p>
                        <b>26.</b> You agree not to sell access to the website.
                    </p>
                    <p></p>
                    <p>
                        <b>27.</b> You agree not to provide unauthorized access to the website. Access to and use of password-protected and/or secure areas of the website are
                        restricted to authorized users only. Unauthorized individuals attempting to access these areas of the website may be subject to prosecution.
                    </p>
                    <p></p>
                    <p>
                        <b>28.</b> You agree not to access or attempt to access any portion of the website to which you have not been granted access, including, the private account
                        information of other users of the website. Access to and use of password-protected and/or secure areas of the website are restricted to authorized users
                        only. Unauthorized individuals attempting to access these areas of the website may be subject to prosecution;
                    </p>
                    <p></p>
                    <p>
                        <b>29.</b> You agree not to use or launch any automated system, including, without limitation, “robots,” “spiders” or “offline readers” that access the website
                        in a manner that sends more requests to the website servers in a given period of time than a human can reasonably produce in the same period by using
                        a conventional online browser;
                    </p>
                    <p></p>
                    <p>
                        <b>30.</b> You agree not to harvest or collect email addresses or other personal and financial information of other users from the website by electronic or
                        other means for the purposes of sending unsolicited communications or improper or illegal activities;
                    </p>
                    <p></p>
                    <p>
                        <b>31.</b> You agree not to use the website in any manner that could damage, disable, overburden or impair the website;
                    </p>
                    <p></p>
                    <p>
                        <b>32.</b> You agree not to upload, post, transmit, share or otherwise make available material that is obscene, pornographic, offensive, discriminatory, false,
                        harmful, harassing, defamatory, libelous, or violates the rights of any third party, including, without limitation, trade secret, patent, copyright, trademark,
                        privacy or publicity.
                    </p>
                    <p></p>
                    <p>
                        <b>33.</b> You agree not to upload, post, transmit, share or otherwise make available any material that contains software viruses or any other computer
                        code, files, or programs designed to interrupt, destroy or limit the functionality of any computer software, hardware, or telecommunications equipment.
                    </p>
                    <p></p>
                    <p>
                        <b>34.</b> You agree to use the website in a manner that complies with all applicable local, national, and international laws and regulations.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>35. FCRA</b>
                    </p>
                    <p></p>
                    <p>
                        You understand and agree that by purchasing the Cyber Sentinel LLC’s products and services you are providing “written instructions” in accordance with the fair
                        credit reporting act, as amended from time to time (“FCRA”), for Cyber Sentinel LLC to access and obtain your personal credit information, and the personal
                        credit information of any minor(s) you have enrolled, from any or all of the credit reporting companies for the purpose of verifying your and any enrolled
                        minor(s)’ identity and to provide the Cyber Sentinel LLC products and services and any services or functions related thereto and Cyber Sentinel LLC’ business.
                    </p>
                    <p></p>
                    <p>
                        The FCRA allows you to obtain a copy of all of the information in your consumer credit file disclosure from any consumer credit reporting company for a
                        reasonable charge. Failure to comply with the FCRA can result in state or federal enforcement actions, as well as private lawsuits. In addition, any person
                        who knowingly and willfully obtains a consumer credit report or disclosure under false pretenses may face criminal prosecution. The FCRA also states
                        that individuals are entitled to receive a disclosure directly from the consumer credit reporting company free of charge under the following
                        circumstances:
                    </p>
                    <p></p>
                    <ul>
                        <li>You have been denied credit, insurance, or employment in the past sixty (60) days as a result of your report.</li>
                        <li>You certify in writing that you are unemployed and intend to apply for employment in the sixty (60)-day period beginning on the day you make the
                            certification.
                        </li>
                        <li>
                            You are a recipient of public welfare assistance.
                        </li>
                        <li>
                            You have reason to believe that your file at the agency contains inaccurate information due to fraud.
                        </li>
                    </ul>
                    <p></p>
                    <p className="text-decoration-underline">
                       <b> 36. Information disclosure</b>
                    </p>
                    <p></p>
                    <p>
                        The FCRA allows consumers to obtain one free comprehensive disclosure of all of the information in their credit file from each of the three major national
                        credit reporting companies (Experian, Equifax, and Transunion) once every twelve (12) months through a central source. Georgia residents can receive
                        two (2) disclosures per year. Although comprehensive, the credit reports from each of the three (3) national credit reporting companies that are available
                        from Cyber Sentinel LLC may not have the same information as a credit report obtained directly from the three (3) national credit reporting companies or
                        through the central source. To request your free annual report under the FCRA, you must go to <b>www.annualcreditreport.com</b>
                    </p>
                    <p></p>
                    <p>
                        Cyber Sentinel LLC products are not related to the free FCRA disclosure that you are or may be entitled to. The FCRA also permits consumers to dispute
                        inaccurate information in their credit reports without charge. Accurate information cannot be changed. You do not have to purchase your credit report or
                        other information from Cyber Sentinel LLC  to dispute inaccurate or incomplete information in your credit file or to receive a copy of your consumer disclosure.
                    </p>
                    <p></p>
                    <p>

                        The credit reports you are requesting from Cyber Sentinel LLC are not intended to constitute the disclosure of the credit reporting company’s information
                        required by the FCRA or similar state laws. The credit reporting company’s disclosure report must be obtained directly from the credit reporting company
                        by contacting the credit reporting company as show below:

                    </p>
                    <p></p>
                    <ul>
                        <li>Equifax: 1-877-576-5734 www.equifax.com</li>
                        <li>Experian: 1-888-397-3742 www.experian.com/fraud</li>
                        <li>Transunion: 1-800-680-7289 www.transunion.com</li>
                    </ul>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b> 37. Sales tax</b>
                    </p>
                    <p></p>
                    <p>
                        If you purchase any products available on the website (products), you may be responsible for paying any applicable sales tax indicated on the website.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                            <b> 38. Fraud</b>
                    </p>
                    <p></p>
                    <p>
                        We reserve the right, but undertake no obligation, to actively report and prosecute actual and suspected credit card fraud. We may, at our discretion,
                        require further authorization from you such as a telephone confirmation of your order and other information. We reserve the right to cancel, delay, refuse
                        to ship, or recall from the shipper any order if fraud is suspected. We capture certain information during the order process, including time, date, IP
                        address, and other information that will be used to locate and identify individuals committing fraud.
                    </p>
                    <p></p>
                    <p>
                        If any website order is suspected to be fraudulent, we reserve the right but undertake no obligation, to submit all records, with or without a subpoena, to
                        all law enforcement agencies and to the credit card company for fraud investigation. We reserve the right to cooperate with authorities to prosecute
                        offenders to the fullest extent of the law.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b> 39. Security</b>
                    </p>
                    <p></p>
                    <p>
                        We employ measures designed to ensure the security of the website, but, as provided below, make no guarantees in this regard.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>40. Copyright</b>
                    </p>
                    <p></p>
                    <p>
                        All materials on the website, including without limitation, the logos, design, text, graphics, other files, and the selection and arrangement thereof are
                        either owned by us or are the property of our suppliers or licensors. You may not use such materials without permission. All rights reserved.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>41. Trademarks</b>

                    </p>
                    <p></p>
                    <p>
                        Cyber Sentinel LLC and <strong>www.yourcybersentinel.com</strong> are trade names we own, and the related design marks and other trademarks on the website are owned by us.
                        Page headers, custom graphics, button icons, and scripts are trademarks or trade dress we own. You may not use any of these trademarks, trade dress, or
                        trade names without our express written permission.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b> 42. Third-party services</b>
                    </p>
                    <p></p>
                    <p>
                        We may use third parties to provide certain services accessible through the website and may provide links to third-party websites. We do not control
                        those third parties, their services, or their websites. We will not be liable to you in any way for your use of such services or websites. These third parties
                        may have their own terms of use and other policies.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>43. Linking and framing </b>
                    </p>
                    <p></p>
                    <p>
                        You may not deep link to portions of the website, or frame, inline link, or similarly display any of our property, including, without limitation, the website.
                        You may not use any of our logos or other trademarks as part of a link without express written permission.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>44. Comments  </b>
                    </p>
                    <p></p>
                    <p>
                        All comments, feedback, suggestions, ideas, and other submissions that you disclose submit, or offer to us in connection with your use of the website
                        (collectively, comments) will become our exclusive property. Such disclosure, submission, or offer of any Comments shall constitute an assignment to us
                        of all worldwide right, title, and interest in all patent, copyright, trademark, and all other intellectual property and other rights whatsoever in and to the
                        comments and a waiver of any claim based on moral rights, unfair competition, breach of implied contract, breach of confidentiality, and any other legal
                        theory.
                    </p>
                    <p></p>
                    <p>
                        You will, at our cost, execute any documents to affect, record, or perfect such assignment. Thus, we will own exclusively all such right, title, and interest
                        and shall not be limited in any way in the use, commercial or otherwise, of any comments. You should not submit any comments to us if you do not wish
                        to assign such rights to us. We are and will be under no obligation: (i) to maintain any comments in confidence; (ii) to pay to you or any third party any
                        compensation for any comments; or (iii) to respond to any comments. You are and shall remain solely responsible for the content of any comments you
                        make.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>45. Indemnification</b>
                    </p>
                    <p></p>
                    <p>

                        To the extent allowed by law, you agree to hold Cyber Sentinel LLC and our employees, representatives, agents, vendors, doctor networks and fulfillers,
                        attorneys, affiliates, directors, officers, managers, and shareholders (the indemnified parties) harmless from any damage, loss, cost or expense (including
                        without limitation, attorney’s fees, and costs) incurred in connection with any third-party claim, demand or action (claim) brought or asserted against any
                        of the indemnified parties arising from, related to, or connected with your use of the web site and/or in association with any purchases you make from us.
                    </p>
                    <p></p>
                    <p>
                        If you are obligated to provide indemnification pursuant to this provision, we may, in our sole and absolute discretion, control the disposition of any claim
                        at your sole cost and expense. Without limitation of the foregoing, you may not settle, compromise or in any other manner dispose of any claim without
                        our consent.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>46. Prohibited practices & non-disparagement </b>
                    </p>
                    <p></p>
                    <p>
                        You shall not use the services or refer, or encourage others to refer, to them or Cyber Sentinel LLC, its customers, owners, officers, directors, personnel, agents,
                        representatives, or affiliated in any manner that is illegal, fraudulent, threatening, abusive, defamatory, or obscene, or that could cause damage or
                        adversely affect its customers, reputation, business, property, or services in any manner.
                    </p>
                    <p></p>
                    <p>
                        You shall not make or encourage others to make any statement or release any information that is intended to, or reasonably could be foreseen to,
                        embarrass, criticize, damage, or adversely affect us, our customers, owners, officers, directors, personnel, agents, representatives or affiliates. (a statement
                        or release of any information under this paragraph includes, but is not limited to, posting on internet websites, bulletin boards, blogs, or discussion
                        groups, and submissions to any publication.)
                    </p>
                    <p></p>
                    <p>
                        Due to the difficulty of ascertaining the pecuniary amount of damages caused by any violation of this section, for each violation of this section, you shall
                        pay liquidated damages in an amount not less than ten times the fees for all services to which you have registered or will register in the future. You
                        agree that this is a reasonable estimate of harm to us.
                    </p>
                    <p></p>
                  
                    <p className="text-decoration-underline">
                        <b>47. Disclaimer of warranties </b>
                    </p>
                    <p></p>
                    <p>
                        We provide the website on an as-is and as-available basis. We do not represent or warrant that the website, its use, and/or any information on it: (i) will
                        be uninterrupted or secure, (ii) will be free of defects, inaccuracies, or errors, (iii) will meet your requirements, or (iv) will operate in the configuration or
                        with other hardware or software you use. We make no warranties other than those made expressly in this TOS, and hereby disclaim any and all implied
                        warranties, including without limitation, warranties of fitness for a particular purpose, MERCHANTABILITY, and non-infringement.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>48. Services </b>
                    </p>
                    <p></p>
                    <p>
                        All services are subject only to any applicable warranties of their respective manufacturers, distributors, and suppliers if any. To the fullest extent
                        permissible by applicable law, we hereby disclaim all warranties of any kind, either express or implied, including without limitation, any implied warranties
                        of MERCHANTABILITY, non-infringement, or fitness for a particular purpose. Without limiting the generality of the foregoing, we hereby expressly disclaim all
                        liability for product defect or failure, claims that are due to normal wear, product misuse, abuse, product modification, improper product selection, non-
                        compliance with any codes, or misappropriation.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>49. Exclusion of damages </b>
                    </p>
                    <p></p>
                    <p>

                        We will not be liable to you or any third party for any consequential, incidental, indirect, punitive, or special damages (including, without limitation,
                        damages relating to lost profits, lost data, or loss of goodwill) arising out of, relating to, or connected with the use of the web site or products, regardless
                        of the cause of action on which they are based, even if advised of the possibility of such damages occurring.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>50. Limitation of liability</b>
                    </p>
                    <p></p>
                    <p>
                        In no event will our aggregate liability arising from, relating to, or in connection with this TOS (including, without limitation, claims relating to the website,
                        or the products) exceed the greater of $100 or the amount that you paid for the product(s).
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                       <b>51. Domestic use; export restriction</b>
                    </p>
                    <p></p>
                    <p>
                        We control the website from our offices within the united states of America. We make no representation that the website or its content (including,
                        without limitation, any products or services available on or through the website) are appropriate or available for use in other locations. Users who access
                        the website from outside the united states of America do so on their own initiative and must bear all responsibility for compliance with local laws, if
                        applicable. Further, the united states export control laws prohibit the export of certain technical data and software to certain territories. No content from
                        the website may be downloaded in violation of united states law.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>52. Force majeure</b>
                    </p>
                    <p></p>
                    <p>

                        We will not be liable for failing to perform under this TOS because of any event beyond our reasonable control, including, without limitation, a labor
                        disturbance, an internet outage or interruption of service, a communications outage, failure by a service provider to perform, fire, terrorism, natural
                        disaster or war.
                    </p>
        
                    <p></p>
                    <p>
                        <b>53.</b> All disputes arising out of or relating to this TOS (including its formation, performance, or alleged breach), your use of the website, and/or any
                        purchases you make from us will be exclusively resolved under confidential binding arbitration held in accordance with the rules of the American
                        arbitration association. The arbitrator’s award will be binding and may be entered as a judgment in any court of competent jurisdiction.
                    </p>
                    <p></p>
                    <p>
                        To the fullest extent permitted by applicable law, no arbitration under this TOS will be joined to an arbitration involving any other party subject to this
                        TOS, whether through class arbitration proceedings or otherwise. Notwithstanding the foregoing, we will have the right to seek injunctive or other
                        equitable relief in state or federal court to enforce this TOS or prevent an infringement of a third party’s rights. In the event equitable relief is sought,
                        each party hereby irrevocably submits to the personal jurisdiction of such court.
                    </p>
                    <p></p>
                    <p>


                        <b>54.</b> The parties are each responsible for their own respective costs relating to counsel, experts, and witnesses, as well as any other costs relating to the
                        arbitration. The filing party will cover any arbitration administrative or filing fees.
                    </p>
                    <p></p>
                    <p>
                        <b>55.</b> In the event that you wish not to be bound by the arbitration obligations of this TOS, you may opt-out of those obligations by notifying us in writing
                        via certified mail within 30 days of the date that you first use the website. Your notice must be mailed to Cyber Sentinel LLC <b>339 8th St N St. Petersburg, FL 33701</b> State your name, address, and account number, and provide a clear statement that you do not wish to resolve disputes with us through
                        arbitration. If you choose to opt-out of this arbitration provision, it will have no adverse effect on your relationship with us or the delivery of services or
                        products to you by us.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>56. Waiver of class action/trial by jury</b>
                    </p>
                    <p></p>
                    <p>
                        By entering into this TOS, you hereby irrevocably waive any right you may have to join claims with those of others in the form of a class action or similar
                        procedural device. Any claims arising out of, relating to, or connected with this TOS must be asserted individually. To the extent allowed by law, we each
                        irrevocably waive any and all rights to trial by jury in any legal proceeding between the parties arising out of or related to this agreement or seller’s
                        services or products.
                    </p>
                    <p></p>
                    <p>
                        You acknowledge and agree that, regardless of any statute or law to the contrary, any claim or cause of action you may have arising out of, relating to, or
                        connected with your use of the website, must be filed within one calendar year after such claim or cause of action arises or forever be barred.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>57. Changes to the website</b>
                    </p>
                    <p></p>
                    <p>
                        We may, in our sole discretion, change, modify, suspend, make improvements to or discontinue any aspect of the website, temporarily or permanently, at
                        any time without notice to you, and we will not be liable for doing so.
                    </p>
                    <p></p>
                    <p>

                        <b>58.</b> We will have the right to terminate your access to the website if we reasonably believe you have breached any of the terms and conditions of this
                        TOS. Following termination, you will not be permitted to use the website and we may, at our discretion, cancel any outstanding product orders. If your
                        access to the website is terminated, we reserve the right to exercise whatever means we deem necessary to prevent unauthorized access to the website,
                        including, but not limited to, technological barriers, IP mapping, and direct contact with your internet service provider. This TOS will survive indefinitely
                        unless and until we choose to terminate it, regardless of whether any account you open is terminated by you or us or if you have the right to access or
                        use the website.
                    </p>
                    <p></p>
                    <p>
                        <b>59.</b> We may, without notice, suspend or terminate any service at any time for any reason, including but not limited to: (a) your breach or suspected
                        breach of this agreement; (b) your use or suspected use of the services and products in any manner inconsistent with this agreement or our policies; (c)
                        you’re providing false, inaccurate, dated or unverifiable identification information, credit information, or other data in connection with your purchase or
                        use of the services and products; (d) your insolvency or bankruptcy; (e) your interference with seller’s operations; or (f) if we believe the action protects its
                        interests or its customer’s interests.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>60. Integration</b>
                    </p>
                    <p></p>
                    <p>

                        This TOS contains the entire understanding between you and us regarding the use of the website and supersedes all prior and contemporaneous
                        agreements and understandings between you and us relating thereto.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        <b>61. Additional terms</b>
                    </p>
                    <p></p>
                    <p>
                        This TOS will be binding upon each party hereto and its successors and permitted assigns. This TOS and all of your rights and obligations under them are
                        not assignable or transferable by you without our prior written consent. No failure or delay by a party in exercising any right, power, or privilege under
                        this TOS will operate as a waiver thereof, nor will any single or partial exercise of any right, power, or privilege preclude any other or further exercise
                        thereof or the exercise of any other right, power, or privilege under this TOS. The invalidity or unenforceable of any provision of this TOS will not affect
                        the validity or enforceability of any other provision of this TOS, all of which will remain in full force and effect.
                    </p>
                    <p></p>
                    <p>
                        We reserve the right to make changes to these TOS at any time. Your continued use of the website constitutes assent to any new or modified provision
                        of this TOS that may be posted on the website. We will act in good faith to update you about changes to these TOS either via email or by a conspicuous
                        posting on the website.
                    </p>
                    <p></p>
                    <p>

                        * identity theft insurance is underwritten by insurance company subsidiaries or affiliates of American International Group, Inc. The description herein is a
                        summary and intended for informational purposes only and does not include all terms, conditions, and exclusions of the policies described. Please refer to
                        the actual policies for terms, conditions, and exclusions of coverage. Coverage may not be available in all jurisdictions.
                    </p>
                </div>
            </section>

            <section className="MembershipTerms__customer-service-container">
                <div className="MembershipTerms__customer-service">
                    <ContactCard />
                </div>
            </section>
        </>
    );
};

export default MembershipTerms;
