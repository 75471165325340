import React, {useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import './NavegationLinks.css';
import CallUsButton from '../../../UiElements/CallUsButton/CallUsButton';

const NavLinks = props => {

  let styles = {
    borderBottom: '3px solid #fbb13b',
  };
  const [activeLink, setActiveLink] = useState(`${sessionStorage.getItem('sectionName') === 'home' ? 'home' : sessionStorage.getItem('sectionName') === 'price' ? 'price'  : 
  window.location.pathname === '/cancel-service' || 
  window.location.pathname === '/privacy-policy' || 
  window.location.pathname ==='/refund-policy' || 
  window.location.pathname ==='/terms-of-service' ? 'home' : window.location.pathname === '/checkout' ? 'price' : ''}`);
  
   const handleHomeClick = () => {
    setActiveLink('');
    window.scrollTo(0, 0);
    sessionStorage.clear();
   };

   

  return (
    <React.Fragment>
    <ul className="nav-links" >
      <li>
        <NavLink  to="/"  onClick={handleHomeClick} style={activeLink === 'home' ? styles : {}} exact>HOME</NavLink> 
      </li>

      <li>

        <NavLink to="/about-us" onClick={handleHomeClick} >ABOUT US</NavLink>  
        
      </li>

      <li>
         <NavLink to="/price" onClick={handleHomeClick} style={activeLink === 'price' ? styles : {}} >PRICE</NavLink>
      </li>

      <li>
        <NavLink to="/contact" onClick={handleHomeClick} >CONTACT</NavLink>
      </li>

      <li>
        <a href='https://portal.yourcybersentinel.com/' target='_blank' >LOGIN</a>
      </li>

       <li className='NavLinks__call-us-button-link' >
        <CallUsButton
        phonenumber="CALL NOW"
        />
      </li> 
    </ul>
    

    </React.Fragment>
  );
};

export default NavLinks;
